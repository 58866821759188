@import '~static/styles/variables';

.ClipboardCopy {
  position: relative;
  cursor: pointer;

  &.empty {
    display: flex;
    height: fit-content;
  }

  &:hover .copyIcon {
    color: $green;
  }

  .hint {
    position: absolute;
    z-index: $dropdown-layer;
    width: 108px;
    bottom: 100%;
    left: 50%;

    padding: 2px 8px;
    opacity: 0;
    background: $green;
    border: 1px solid $dark-green;
    box-shadow: 0px 8px 24px rgba($black, 0.16);
    border-radius: 4px;
    transform: translate(-50%);
    transition: 0.3s transform, 0.3s opacity;

    color: $white;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    &.copied {
      width: 150px;
    }

    &.alignRight {
      transform: none;
      left: unset;
      right: 0;
    }
  }

  .copyIcon {
    color: $light-gray;
    width: 100%;
    height: auto;
    transition: all 0.2s ease-in-out;
  }
}
