@import '~static/styles/mixins';
@import '~static/styles/variables';

.PreloaderPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100%;
  width: 100%;
  background: $night-green;

  .logo {
    animation: fadeUp 1s ease-in-out;

    .icon {
      height: 64px;
      margin: 0 0 30px;

      @include respond-above(lg) {
        height: 133px;
        margin: 0 0 60px;
      }
    }

    .text {
      font-size: 35px;
      line-height: 100%;

      @include respond-above(lg) {
        font-size: 65px;
      }
    }
  }

  @keyframes fadeUp {
    from {
      opacity: 0;
      margin: 0 0 -30px;
    }
    to {
      opacity: 1;
      margin: 0 0 0;
    }
  }
}
