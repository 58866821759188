@import '~static/styles/mixins';
@import '~static/styles/variables';

.Toolbar {
  position: fixed;
  top: 66px;
  right: 0;
  z-index: $menu-overlay-layer;

  display: flex;
  flex-direction: column;

  @include respond-below(lg) {
    width: 100%;
    height: 30px;
  }

  @include respond-above(lg) {
    // top: 0;
    // flex-direction: row;
    // align-items: center;
    // padding: 24px 52px;

    display: none;
  }

  .avatar {
    box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.32);
    margin: 0 0 0 32px;

    @include respond-below(lg) {
      display: none;
    }
  }

  .chat {
    display: none;
    margin: 0 0 0 32px;

    .icon {
      width: 24px;
      height: auto;
    }
  }

  .balance {
    color: $black;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    @include respond-below(lg) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: $bone-white;
      background: $dark-gray;
      width: 100%;
      padding: 6px 24px;
    }

    .label {
      opacity: 0.7;

      @include respond-above(lg) {
        display: none;
      }
    }
  }
}
