@import './variables';

@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
}

@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  }
}

@mixin pretty-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: rgba($green, 0.3);
    border-radius: 20px;
    width: 3px;
    height: 3px;
    // border-top: 10px transparent solid;
    // border-bottom: 10px transparent solid;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: $green;
    transition: all 0.5s;
    // border-top: 10px transparent solid;
    // border-bottom: 10px transparent solid;
    background-clip: padding-box;
    cursor: pointer;
  }
}
