@import '~static/styles/mixins';
@import '~static/styles/variables';

.Sidebar {
  position: fixed;
  top: 0;
  width: 220px;
  height: 100%;
  overflow: auto;
  background: $night-green;

  display: flex;
  flex-direction: column;
  z-index: $menu-content-layer;

  @include pretty-scrollbar;

  @include respond-above(lg) {
    left: 0;
  }

  @include respond-below(lg) {
    width: 267px;
    height: calc(100% - 66px);
    top: 66px;
    right: 0;
  }

  &.winter-1 {
    .content {
      background-image: url('~static/images/sidebar-winter-top-bg.png'),
        url('~static/images/sidebar-winter-bottom-bg-1.png');
      background-position: top left, bottom left;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }
  &.winter-2 {
    .content {
      background-image: url('~static/images/sidebar-winter-top-bg.png'),
        url('~static/images/sidebar-winter-bottom-bg-2.png');
      background-position: top left, bottom left;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }
  &.winter-3 {
    .content {
      background-image: url('~static/images/sidebar-winter-top-bg.png'),
        url('~static/images/sidebar-winter-bottom-bg-3.png');
      background-position: top left, bottom left;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include pretty-scrollbar;
  }

  .languageSelect {
    margin: 20px auto 40px 0;
    width: min-content;
    padding: 0 0 0 32px;

    @include respond-above(lg) {
      order: -1;
      margin: 20px auto 20px 0;
    }

    :global {
      .Select__control {
        padding-left: 0;
        box-shadow: none;
      }

      .Select__dropdown-indicator svg {
        color: $white;
      }

      .Select__single-value {
        color: $white;
      }
    }
  }

  .logoLink {
    margin: 40px auto 20px 40px;

    @include respond-below(lg) {
      display: none;
    }

    @include respond-above(lg) {
      order: -2;
    }
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;

    @include respond-below(lg) {
      padding: 32px 32px 0 0;
    }

    @include respond-above(lg) {
      margin: 0 0 70px;
    }

    .userInfo {
      display: flex;
      align-items: center;
      margin: 0 0 0 32px;

      .avatar {
        width: 40px;
        height: 40px;
        margin: 0 16px 0 0;
      }

      .text {
        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
          display: none;
        }

        .username {
          margin: 0 0 4px;
        }

        .name {
          margin: 0;
          opacity: 0.7;
        }
      }
    }

    .balance {
      color: $white;
      margin-right: 32px;

      @include respond-below(lg) {
        display: none;
      }
    }

    .close {
      // margin: 0 0 32px auto;
      color: $bone-white;

      @include respond-above(lg) {
        display: none;
      }

      .icon {
        width: 16px;
      }
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include respond-above(lg) {
      padding: 0 0 0 20px;
    }

    .link {
      position: relative;
      width: 100%;
      padding: 10px 10px 10px 32px;
      border-radius: 8px 0 0 8px;
      transition: all 0.2s ease-in-out;

      color: rgba($white, 0.6);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;

      @include respond-above(lg) {
        padding: 12px 20px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        background: $green;
        border-radius: 4px 0 0 4px;
        width: 4px;
        height: 100%;
        opacity: 0;
        transition: all 0.2s ease-in-out;

        @include respond-above(lg) {
          right: 0;
        }

        @include respond-below(lg) {
          border-radius: 0px 8px 8px 0px;
          width: 8px;
          left: 0;
        }
      }

      &:hover {
        color: $white;
      }

      &.active {
        color: $white;

        @include respond-above(lg) {
          background: rgba($white-gray, 0.1);
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .snowSwitcher {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 32px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @include respond-above(lg) {
      padding: 12px 20px;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.9;
    }

    &.enabled {
      .switcher {
        background: #0185fe;
        box-shadow: 0px 16px 8px 0px rgba(1, 133, 254, 0.03), 0px 4px 8px 0px rgba(1, 133, 254, 0.16),
          0px 16px 10px -9px rgba(1, 133, 254, 0.16), 0px 0px 4px 0px rgba(1, 133, 254, 0.08);

        .dot {
          left: 22px;
          color: #0185fe;
        }
      }
    }

    input {
      display: none;
    }

    .switcher {
      position: relative;
      width: 44px;
      height: 24px;
      margin: 0 16px 0 0;
      border-radius: 100px;
      background: linear-gradient(180deg, #001629 0%, #001d37 100%);
      transition: all 0.2s ease-in-out;

      .dot {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        left: 2px;
        top: 2px;
        color: #011a32;
        transition: all 0.2s ease-in-out;

        border-radius: 50%;
        background: linear-gradient(180deg, #fff -7.14%, #b9b9b9 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
      }

      .icon {
        width: 16px;
        height: auto;
      }
    }
  }

  .logout {
    margin: 220px auto 16px 40px;

    color: $black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    @include respond-above(lg) {
      margin: 180px auto 16px 40px;
    }

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $menu-overlay-layer;
  background: rgba($black, 0.8);

  @supports (backdrop-filter: blur(4px)) {
    backdrop-filter: blur(4px);
  }

  @include respond-above(lg) {
    display: none;
  }
}
