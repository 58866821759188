@import '~static/styles/mixins';
@import '~static/styles/variables';

.MagicBoxLotCard {
  display: flex;
  flex-direction: column;

  padding: 16px;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .status {
      display: flex;
      align-items: center;

      .icon {
        height: 16px;
        width: auto;
        margin: 0 6px 0 0;

        path {
          fill: $black-transparent;
        }
      }

      &.active {
        .icon path {
          fill: $green;
        }
        span {
          color: $green;
        }
      }
    }

    .controls {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-flow: column;
      gap: 16px;
      color: $gray;

      .button {
        color: $gray;

        &:hover {
          color: $red;
        }
      }

      .icon {
        width: 16px;
        height: auto;
      }
    }
  }

  .info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;

    &.main {
      flex: 1;
    }

    .item {
      padding: 8px !important;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px 0 0;

      .label {
        margin: 0 0 4px;
      }

      &.button {
        border-radius: 8px;
        background: rgba(#d9d9d9, 0.2);

        .icon {
          position: absolute;
          right: 16px;
          bottom: 12px;

          color: $black;
          width: 16px;
          height: auto;
        }
      }
    }
  }

  .user {
    display: flex;
    align-items: center;

    .avatar {
      margin: 0 10px 0 0;
      flex-shrink: 0;
    }

    .userInfo {
      display: flex;
      flex-direction: column;
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    align-items: center;

    .copy .icon {
      width: 24px;
      height: auto;
      color: $gray;
    }
  }

  .delimeter {
    margin: 16px 0;
    width: 100%;
    height: 1px;
    background: rgba($black, 0.1);
  }
}
