@import '~static/styles/mixins';
@import '~static/styles/variables';

.TopboxNodeInfoModal {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding: 16px;
  max-width: 240px;
  width: 100%;

  background: rgba(13, 13, 25, 0.9);
  backdrop-filter: blur(12px);
  color: $white;

  .heading {
    display: flex;
    flex-direction: column;
  }

  .line {
    margin: 8px 0 0;
    padding: 2px 8px;
    border-radius: 16px;
    background: $green;
    width: min-content;
    white-space: nowrap;
  }

  .info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    width: 100%;

    .item {
      display: flex;
      flex-direction: column;
    }

    .label {
      color: $light-gray;
      margin: 0 0 4px;
    }

    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .control {
      margin: 0 0 0 8px;

      .icon {
        height: 16px;
        width: auto;
        color: $light-gray;
      }
    }
  }
}

.nodeInfoModalOverlay {
  background: transparent;
  backdrop-filter: none;
}
