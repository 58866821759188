@import '~static/styles/mixins';
@import '~static/styles/variables';

.ReviewCard {
  display: flex;
  flex-direction: column;

  padding: 16px;

  .date {
    text-transform: capitalize;
    margin: 0 0 16px;
  }

  .text {
    overflow: auto;
    margin: 0 -8px 16px 0;
    padding: 0 8px 0 0;

    width: calc(100% + 8px);
    max-height: 120px;

    white-space: pre-wrap;
    word-break: break-word;

    @include pretty-scrollbar;

    @include respond-above(lg) {
      max-height: 140px;
    }
  }

  .user {
    display: flex;
    align-items: center;

    margin: auto 0 0;

    .avatar {
      flex-shrink: 0;
      margin: 0 8px 0 0;
    }

    .username {
      margin: 0;
    }
  }
}
