@import '~static/styles/mixins';
@import '~static/styles/variables';

.Pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: auto;

  li {
    margin: 0 2px;
  }

  .page {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 0;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid $white-gray;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;

    color: $black;
    font-size: 12px 20px;
    font-weight: 400;

    @include respond-above(lg) {
      width: 40px;
      height: 40px;
      border-radius: 10px;

      font-size: 16px;
      line-height: 24px;
    }

    &:not(:disabled) {
      cursor: pointer;

      &:hover:not(.active) {
        color: $green;
      }
    }

    svg {
      height: auto;
      width: 20px;

      @include respond-above(lg) {
        width: 24px;
      }
    }

    &.active {
      background-color: $green;
      color: $white;
      border-color: $green;
    }

    &:active {
      color: $green;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}
