@import '~static/styles/mixins';
@import '~static/styles/variables';

.TwoFactorSettingsModal {
  max-width: 480px;

  .title {
    margin: 0 0 16px;
  }

  .text {
    margin: 0 0 24px;
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
