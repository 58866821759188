@import '~static/styles/mixins';
@import '~static/styles/variables';

.AdminDeleteNotificationModal {
  padding: 16px;
  max-width: 420px;
  width: 100%;

  .message {
    text-align: center;
    margin: 0 0 16px;
  }

  .controls {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}
