@import '~static/styles/variables';

.Checkbox {
  display: inline-flex;

  &:not(.disabled) {
    cursor: pointer;
  }

  &:hover {
    .button {
      box-shadow: inset 0 0 0 2px $green;
    }
  }

  input {
    display: none;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 1px;
    background: transparent;
    box-shadow: inset 0 0 0 2px $light-gray;
    border-radius: 5px;
    padding: 0;
    flex-shrink: 0;
    transition: all 0.2s ease-in-out;
    border: none;
    pointer-events: none;

    svg {
      width: 12px;
      height: auto;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  input:checked ~ .button {
    overflow: hidden;
    background: $green;
    box-shadow: none;

    svg {
      opacity: 1;
    }
  }

  .label {
    margin: 0 0 0 10px;
    color: $black;
    text-align: left;
  }

  &.disabled {
    opacity: 0.4;
  }

  &.hasError {
    .button {
      box-shadow: inset 0 0 0 2px $red;
    }
  }
}
