@import '~static/styles/mixins';
@import '~static/styles/variables';

.AdminNotificationFormModal {
  max-width: 420px;
  width: 100%;

  .title {
    margin: 0 0 16px;
  }

  .form {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 12px;

    .controls {
      margin: 16px 0 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      width: 100%;
    }
  }

  .text {
    text-align: left;
    margin: 0 0 40px;
  }

  .button {
    width: 100%;
  }
}
