@import '~static/styles/variables';

.LanguageSelect {
  width: auto;

  .flagIcon {
    border-radius: 50%;
    overflow: hidden;
    margin: 0 10px 0 0;
  }

  :global {
    .Select {
      &__control {
        box-shadow: none;
        background: none;
        padding: 0;

        &:hover,
        &--is-focused,
        &--is-focused:hover {
          box-shadow: none;
          border: none;
        }
      }

      &__menu {
        width: auto;

        &-list {
          padding: 10px;
        }
      }

      &__option,
      &__single-value {
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
      }
    }
  }
}
