@import '~static/styles/mixins';
@import '~static/styles/variables';

.AdminFilterModal {
  max-width: 300px;
  width: 100%;

  @include respond-above(lg) {
    max-width: 640px;
  }

  .title {
    margin: 0 0 16px;
  }

  .form {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 12px;

    @include respond-above(lg) {
      grid-template-columns: 1fr 1fr;
    }

    .controls {
      margin: 16px 0 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      width: 100%;

      @include respond-above(lg) {
        grid-column: span 2;
      }
    }
  }

  .message {
    text-align: left;
    margin: 0 0 40px;
  }

  .button {
    width: 100%;
  }
}
