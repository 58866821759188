@import '~static/styles/mixins';
@import '~static/styles/variables';

.AdminNotificationCard {
  padding: 16px;
  display: flex;
  flex-direction: row;

  .data {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    width: 100%;

    .info {
      margin: 0;

      .link {
        display: inline;
      }
    }

    .message {
      white-space: pre-wrap;
      word-break: break-word;
      margin: 0;
    }
  }

  .controls {
    margin: 0 0 auto 18px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    flex-shrink: 0;

    .button {
      color: $gray;

      &.edit:hover {
        color: $green;
      }

      &.delete:hover {
        color: $red;
      }

      .icon {
        width: 18px;
        height: auto;
      }
    }
  }
}
