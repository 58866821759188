@import '~static/styles/mixins';
@import '~static/styles/variables';

.PurchaseKlondikePlacesModal {
  max-width: 260px;

  .title {
    margin: 0 0 16px;
  }

  .text {
    margin: 0 0 24px;
  }

  .counter {
    display: grid;
    height: 48px;
    grid-template-columns: 48px 1fr 48px;
    gap: 5px;
    margin: 0 0 24px;

    .input {
      input {
        padding: 11px;
        box-shadow: inset 0 0 0 1px $light-gray;

        text-align: center;
        color: $bone-white;
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
      }

      &:hover,
      &:active {
        input {
          box-shadow: inset 0 0 0 1px $bone-white;
        }
      }
    }

    .increment,
    .decrement {
      height: 100%;
      width: 100%;
      padding: 0;

      .icon {
        width: 24px;
        height: auto;
      }
    }
  }

  .label {
    margin: 0 0 8px;
  }

  .price,
  .clonesToPurchase {
    margin: 0 0 40px;

    span {
      color: $green;
    }
  }

  .controls {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
