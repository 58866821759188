@import '~static/styles/mixins';
@import '~static/styles/variables';

.RemoveFromStructureModal {
  padding: 20px;
  max-width: 380px;

  .title {
    margin: 0 0 16px;
  }

  .text {
    margin: 0 0 16px;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    width: 100%;
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
