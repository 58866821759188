@import '~static/styles/mixins';
@import '~static/styles/variables';

.overlay {
  position: fixed;
  flex: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: $modal-overlay-layer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0;
  background: rgba($black, 0.8);

  @supports (backdrop-filter: blur(4px)) {
    backdrop-filter: blur(4px);
  }

  @include respond-above(md) {
    padding: 80px 0;
  }

  @include respond-above(xxl) {
    padding: 100px 0;
  }

  &.unwrapped {
    background: rgba($black, 0.9);
  }

  &.noClose {
    padding: 20px 0;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: $white;

    @include respond-above(md) {
      top: 30px;
      right: 30px;
    }

    @include respond-above(xxl) {
      top: 40px;
      right: 40px;
    }

    .icon {
      width: 32px;
      height: auto;
    }
  }
}

.card {
  max-width: calc(100% - 30px);
  padding: 24px 16px 16px;
  margin: auto;
  z-index: $modal-content-layer;
  background: $bone-white;
  border-radius: 16px;

  &.unwrapped {
    background: transparent;
    padding: 0;
  }

  &.headed {
    padding: 0;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 24px 64px 24px 24px;
    border-radius: 16px 16px 0 0;
    background: linear-gradient(31.21deg, #0c272d 0%, #00b548 286.84%);

    color: $white;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    .close {
      top: 16px;
      right: 16px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 16px;
  }
}
