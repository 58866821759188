@import '~static/styles/mixins';
@import '~static/styles/variables';

.UserBoomboxClonesModal {
  max-width: 340px;
  width: 100%;

  .card {
    position: relative;
    width: 100%;
    margin: 0 0 24px;
    padding: 8px;
  }

  .spinner {
    margin: auto;
    z-index: 1;
  }

  .noClones {
    margin: auto;
    text-align: center;
  }

  .list {
    overflow: auto;
    padding: 0 8px 0 0;

    @include pretty-scrollbar;

    .item {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      appearance: none;
      border-radius: 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba($light-gray, 0.3);
      }

      .info {
        display: flex;
        flex-direction: column;
      }

      .createdAt {
        text-align: right;
      }
    }
  }

  .pagination {
    margin: 16px auto 0;
  }

  .button {
    width: 100%;
  }
}
