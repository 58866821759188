@import '~static/styles/variables';

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;
  border-radius: 10px;
  border: none;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  font-family: $inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  // Sizes

  &.small {
    padding: 8px 20px;
    border-radius: 6px;

    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

    .spinner {
      width: 18px;
    }

    .iconStart,
    .iconEnd {
      width: 16px;
    }
  }

  &.medium {
    padding: 10px 22px;
    border-radius: 8px;

    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    .spinner {
      width: 18px;
    }

    .iconStart,
    .iconEnd {
      width: 18px;
    }
  }

  &.large {
    padding: 12px 24px;
    border-radius: 10px;

    font-weight: 600;
    font-size: 15px;
    line-height: 26px;

    .spinner {
      width: 26px;
    }

    .iconStart,
    .iconEnd {
      width: 20px;
    }
  }

  // Colors

  &.green {
    --contained-bg-color: #{$green};
    --contained-bg-color-hover: #{$dark-green};
    --contained-bg-color-active: #{rgba($dark-green, 0.9)};
    --contained-bg-color-disabled: #{$green-transparent};
    --contained-text-color: #{$white};
    --contained-text-color-disabled: #{$white};

    --outlined-color: #{$green};
    --outlined-color-disabled: #{$green-transparent};

    --text-color: #{$green};
    --text-color-hover: #{$green};
    --text-color-active: #{rgba($green, 0.9)};
    --text-color-disabled: #{$green-transparent};
  }

  &.red {
    --contained-bg-color: #{$crimson-red};
    --contained-bg-color-hover: #{rgba($crimson-red, 0.8)};
    --contained-bg-color-active: #{rgba($crimson-red, 0.9)};
    --contained-bg-color-disabled: #{rgba($crimson-red, 0.4)};
    --contained-text-color: #{$white};
    --contained-text-color-disabled: #{$white};

    --outlined-color: #{$crimson-red};
    --outlined-color-disabled: #{rgba($crimson-red, 0.4)};

    --text-color: #{$crimson-red};
    --text-color-hover: #{$crimson-red};
    --text-color-active: #{rgba($crimson-red, 0.9)};
    --text-color-disabled: #{rgba($crimson-red, 0.4)};
  }

  &.blue {
    --contained-bg-color: #{$blue};
    --contained-bg-color-hover: #{rgba($blue, 0.8)};
    --contained-bg-color-active: #{rgba($blue, 0.9)};
    --contained-bg-color-disabled: #{rgba($blue, 0.4)};
    --contained-text-color: #{$white};
    --contained-text-color-disabled: #{$white};

    --outlined-color: #{$blue};
    --outlined-color-disabled: #{rgba($blue, 0.4)};

    --text-color: #{$blue};
    --text-color-hover: #{$blue};
    --text-color-active: #{rgba($blue, 0.9)};
    --text-color-disabled: #{rgba($blue, 0.4)};
  }

  &.black {
    --contained-bg-color: #{$black};
    --contained-bg-color-hover: #{rgba($black, 0.8)};
    --contained-bg-color-active: #{rgba($black, 0.9)};
    --contained-bg-color-disabled: #{$light-gray};
    --contained-text-color: #{$white};
    --contained-text-color-disabled: #{$white};

    --outlined-color: #{$black};
    --outlined-color-disabled: #{$light-gray};

    --text-color: #{$black};
    --text-color-hover: #{$black};
    --text-color-active: #{rgba($black, 0.9)};
    --text-color-disabled: #{$light-gray};
  }

  &.gray {
    --contained-bg-color: #{$dark-gray};
    --contained-bg-color-hover: #{rgba($dark-gray, 0.8)};
    --contained-bg-color-active: #{rgba($dark-gray, 0.9)};
    --contained-bg-color-disabled: #{rgba($dark-gray, 0.4)};
    --contained-text-color: #{$white};
    --contained-text-color-disabled: #{$white};

    --outlined-color: #{$dark-gray};
    --outlined-color-disabled: #{rgba($dark-gray, 0.4)};

    --text-color: #{$dark-gray};
    --text-color-hover: #{$dark-gray};
    --text-color-active: #{rgba($dark-gray, 0.8)};
    --text-color-disabled: #{rgba($dark-gray, 0.4)};
  }

  &.lightGray {
    --contained-bg-color: #{$light-gray};
    --contained-bg-color-hover: #{rgba($light-gray, 0.8)};
    --contained-bg-color-active: #{rgba($light-gray, 0.9)};
    --contained-bg-color-disabled: #{rgba($light-gray, 0.4)};
    --contained-text-color: #{$white};
    --contained-text-color-disabled: #{$white};

    --outlined-color: #{$light-gray};
    --outlined-color-disabled: #{rgba($light-gray, 0.4)};

    --text-color: #{$light-gray};
    --text-color-hover: #{$light-gray};
    --text-color-active: #{rgba($light-gray, 0.8)};
    --text-color-disabled: #{rgba($light-gray, 0.4)};
  }

  &.white {
    --contained-bg-color: #{$white};
    --contained-bg-color-hover: #{rgba($white, 0.8)};
    --contained-bg-color-active: #{rgba($white, 0.9)};
    --contained-bg-color-disabled: #{rgba($white, 0.4)};
    --contained-text-color: #{$green};
    --contained-text-color-disabled: #{$green};

    --outlined-color: #{$white};
    --outlined-color-disabled: #{rgba($white, 0.4)};

    --text-color: #{$white};
    --text-color-hover: #{$white};
    --text-color-active: #{rgba($white, 0.8)};
    --text-color-disabled: #{rgba($white, 0.4)};
  }

  &.boneWhite {
    --contained-bg-color: #{$bone-white};
    --contained-bg-color-hover: #{rgba($bone-white, 0.8)};
    --contained-bg-color-active: #{rgba($bone-white, 0.9)};
    --contained-bg-color-disabled: #{rgba($bone-white, 0.4)};
    --contained-text-color: #{$green};
    --contained-text-color-disabled: #{$green};

    --outlined-color: #{$bone-white};
    --outlined-color-disabled: #{rgba($bone-white, 0.4)};

    --text-color: #{$bone-white};
    --text-color-hover: #{$bone-white};
    --text-color-active: #{rgba($bone-white, 0.8)};
    --text-color-disabled: #{rgba($bone-white, 0.4)};
  }

  // Variants

  &.contained {
    background-color: var(--contained-bg-color);
    color: var(--contained-text-color);

    &:hover {
      background-color: var(--contained-bg-color-hover);
    }

    &:active {
      background-color: var(--contained-bg-color-active);
    }

    &.disabled {
      background-color: var(--contained-bg-color-disabled);
      color: var(--contained-text-color-disabled);
    }
  }

  &.outlined {
    background-color: transparent;
    color: var(--outlined-color);
    box-shadow: inset 0 0 0 1px var(--outlined-color);

    &:hover {
      background-color: var(--contained-bg-color);
      color: var(--contained-text-color);
      box-shadow: none;
    }

    &:active {
      background-color: var(--contained-bg-color-active);
      color: var(--contained-text-color);
      box-shadow: none;
    }

    &.disabled {
      box-shadow: inset 0 0 0 1px var(--outlined-color-disabled);
      color: var(--outlined-color-disabled);
    }
  }

  &.text {
    display: inline-flex;
    align-items: center;
    background: none;
    padding: 0;
    color: var(--text-color);
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;

    &:hover {
      color: var(--text-color-hover);
      text-decoration: underline;
    }

    &:active {
      color: var(--text-color-active);
      text-decoration: underline;
    }

    &.disabled {
      color: var(--text-color-disabled);
    }
  }

  &.wrapper {
    background: none;
    padding: 0;
    display: flex;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.9;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &:not(.wrapper) svg {
    color: currentColor;

    [fill] {
      fill: currentColor;
    }
    [stroke] {
      stroke: currentColor;
    }
  }

  .spinner,
  .iconEnd,
  .iconStart {
    height: auto;
  }

  .iconStart {
    margin: 0 8px 0 0;
  }

  .iconEnd {
    margin: 0 0 0 8px;
  }
}
