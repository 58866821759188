@import '~static/styles/mixins';
@import '~static/styles/variables';

$typography-styles: (
  heading1: (
    font-weight: 700,
    font-size: 64px,
    line-height: 80px,
  ),
  heading2: (
    font-weight: 600,
    font-size: 56px,
    line-height: 70px,
  ),
  heading3: (
    font-weight: 600,
    font-size: 42px,
    line-height: 51px,
  ),
  heading4: (
    font-weight: 700,
    font-size: 36px,
    line-height: 44px,
  ),
  heading5: (
    font-weight: 700,
    font-size: 28px,
    line-height: 32px,
  ),
  heading6: (
    font-weight: 600,
    font-size: 24px,
    line-height: 29px,
  ),
  subtitle1: (
    font-weight: 600,
    font-size: 16px,
    line-height: 20px,
  ),
  subtitle2: (
    font-weight: 700,
    font-size: 14px,
    line-height: 18px,
  ),
  paragraph1: (
    font-weight: 400,
    font-size: 16px,
    line-height: 24px,
  ),
  paragraph2: (
    font-weight: 400,
    font-size: 14px,
    line-height: 20px,
  ),
  caption1: (
    font-weight: 400,
    font-size: 12px,
    line-height: 15px,
  ),
  caption2: (
    font-weight: 700,
    font-size: 12px,
    line-height: 15px,
  ),
);

@mixin typography-styles {
  @each $variant, $styles in $typography-styles {
    &.#{$variant} {
      font-weight: map-get($styles, 'font-weight');
      font-size: map-get($styles, 'font-size');
      line-height: map-get($styles, 'line-height');
    }

    @each $breakpoint, $value in $breakpoints {
      &.#{$breakpoint}-#{$variant} {
        @include respond-below($breakpoint) {
          font-weight: map-get($styles, 'font-weight');
          font-size: map-get($styles, 'font-size');
          line-height: map-get($styles, 'line-height');
        }
      }
    }
  }
}

.Typography {
  @include typography-styles;

  // Colors
  &.boneWhite {
    color: $bone-white;
  }

  &.nightGreen {
    color: $night-green;
  }

  &.green {
    color: $green;
  }

  &.black {
    color: $black;
  }

  &.blackTransparent {
    color: $black-transparent;
  }

  &.white {
    color: $white;
  }

  &.darkGray {
    color: $dark-gray;
  }

  &.brightGray {
    color: $bright-gray;
  }

  &.lightGreen {
    color: $light-green;
  }

  &.red {
    color: $red;
  }
}
