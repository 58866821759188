@import '~static/styles/mixins';
@import '~static/styles/variables';

.NumberFieldWithControls {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  transition: all 0.2s ease-in-out;
  cursor: text;

  &.hasError {
    .input input {
      box-shadow: inset 0 0 0 1px $red;
    }

    .label {
      color: $red;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .label {
    text-align: left;
    margin: 0 0 8px;
    transition: all 0.2s ease-in-out;
  }

  .fieldWrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
    width: 100%;

    .button {
      box-shadow: inset 0 0 0 1px $white-gray;

      &:hover,
      &:active {
        box-shadow: none;
      }

      &:hover {
        background: $dark-green;
      }

      &:active {
        background: rgba($dark-green, 0.9);
      }
    }
  }

  .input input {
    text-align: center;
  }
}
