@import '~static/styles/mixins';
@import '~static/styles/variables';

.NotFoundPage {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $night-green;
  z-index: $dropdown-layer;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  //max-width: 360px;
}

.notFoundPageHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #d6e1e1;
  margin-bottom: 16px;
  margin-top: 0;
}

.notFoundPageDescription {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #7c979d;
  margin-bottom: 56px;
  max-width: 360px;
}

.notFoundPageLinkWrapper {
  padding: 12px 34px;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: $white;
  background: $green;
  border-radius: 10px;
  text-decoration: none;
  margin-bottom: auto;
}

.icon {
  transform: translateY(80px);
  max-width: 100%;
}

.logoIcon {
  transform: translateX(28px);
  max-width: 100%;
}
