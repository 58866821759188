@import '~static/styles/mixins';
@import '~static/styles/variables';

.PromoModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: calc(100% - 48px);
  max-width: 520px;
  padding: 24px 16px;

  // background: linear-gradient(115.22deg, #0c272d -38.37%, #053d49 -38.35%, #043f43 4.17%, #004a1e 135.61%);
  background: url('~static/images/speedbox/s1-available-card-bg.png'), $white;
  background-size: cover;
  background-position: center;

  @include respond-above(md) {
    padding: 40px 30px;
  }

  .title {
    // margin: 0 0 24px;
    text-transform: uppercase;
    line-height: 120%;
    text-shadow: 0px 0px 53.7149px rgba(0, 229, 43, 0.28);
    font-family: $montserrat;
    font-weight: 700;

    @include respond-above(lg) {
      line-height: 108%;
    }
  }

  .subtitle {
    margin: 0 0 24px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .link {
    margin: 0;
  }

  .lightbulb {
    height: 120px;
    margin: 0 0 24px;

    @include respond-above(md) {
      margin: 0 24px 0 0;
    }
  }

  .box {
    position: absolute;
    pointer-events: none;
    width: 550px;
    height: auto;

    right: -182px;
    bottom: -184px;

    @include respond-below(lg) {
      display: none;
    }
  }

  .shadowedCoin {
    position: absolute;
    width: 92px;
    height: auto;

    &.first {
      top: -36px;
      right: 17px;
    }

    &.second {
      transform: rotate(-20deg);
      bottom: -42px;
      right: 14px;
    }
  }

  .coin {
    position: absolute;
    top: -20px;
    left: 40px;
    transform: rotate(45deg);
    width: 42px;
    height: auto;
  }

  .coinBlured {
    position: absolute;
    top: -28px;
    right: 148px;

    height: 28px;
    width: 60px;
    object-position: top;
    object-fit: cover;
  }

  .close {
    position: absolute;
    top: -40px;
    right: 0;
    color: $white;

    @include respond-above(lg) {
      top: 0;
      right: -40px;
    }

    .icon {
      width: 32px;
      height: auto;
    }
  }
}
