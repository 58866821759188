@import '~static/styles/mixins';
@import '~static/styles/variables';

.DepositModal {
  width: 300px;

  .title {
    margin: 0 0 16px;
  }

  .form {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 8px;

    .controls {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      width: 100%;
    }
  }

  .message {
    text-align: left;
    margin: 0 0 40px;
  }

  .button {
    width: 100%;
  }
}
