@import '~static/styles/mixins';
@import '~static/styles/variables';

.CreateMagicBoxLotModal {
  max-width: 330px;
  width: 100%;

  .form {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 16px;

    .delimeter {
      width: 100%;
      height: 1px;
      background: rgba($black, 0.1);
    }

    .input {
      & > span:first-child {
        font-weight: 600;
      }

      :global {
        .Select__value-container {
          flex: 1;
        }

        .Select__single-value {
          flex: 1;
          white-space: wrap;
        }
      }
    }

    .fieldWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;

      .label {
        margin: 0 0 8px;
      }
    }

    .text {
      text-align: center;
    }

    .controls {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;
    }

    .button {
      width: 100%;

      &.close {
        box-shadow: none;
      }
    }
  }
}
