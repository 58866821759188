@import '~static/styles/mixins';
@import '~static/styles/variables';

.Logo {
  display: flex;

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .boxIconWrapper {
      margin: 0 24px 0 0;
    }
  }

  &.vertical,
  &.verticalCentered {
    flex-direction: column;

    .boxIconWrapper {
      margin: 0 0 24px;
    }
  }

  &.vertical {
    align-items: flex-start;
  }

  &.verticalCentered {
    align-items: center;
  }

  .boxIcon,
  .text span {
    filter: drop-shadow(0px 0px 40px $green);
  }

  .boxIcon {
    height: 40px;
    width: auto;
  }

  .text {
    span {
      color: $green;
    }
  }

  .boxIconWrapper {
    position: relative;

    .hat {
      position: absolute;
      top: -11px;
      right: -13px;
    }
  }
}
