@import '~static/styles/mixins';
@import '~static/styles/variables';

.UserFieldCard {
  flex-direction: row;
  align-items: center;
  padding: 16px;

  @include respond-above(lg) {
    padding: 24px;
  }

  .button {
    flex-shrink: 0;
    border-radius: 40px;
    padding: 6px 16px;
    margin: 0 0 0 12px;

    &:not(.clear) {
      @include respond-below(lg) {
        box-shadow: none;
      }
    }

    &.clear {
      width: 30px;
      height: 30px;
      margin: 0 0 0 8px;
      padding: 0;

      .icon {
        width: 16px;
        height: auto;
      }
    }

    svg {
      @include respond-below(lg) {
        margin: 0;
        width: 24px;
      }
    }

    span {
      @include respond-below(lg) {
        display: none;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    margin: 0 auto 0 0;
    max-width: 360px;

    .value {
      margin: 0 0 8px;
    }

    .label {
      margin: 0;

      .icon {
        width: 16px;
        height: auto;
        margin: 0 8px 0 0;
      }
    }
  }
}
