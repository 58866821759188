@import '~static/styles/mixins';
@import '~static/styles/variables';

.CreateReviewModal {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;

  .title {
    margin: 0 0 16px;
  }

  .text {
    margin: 0 0 32px;
  }

  .successImg {
    width: 80px;
    height: auto;
    margin: 0 auto 16px;
  }

  .successImg ~ * {
    text-align: center;
  }

  .successImg ~ .button {
    box-shadow: none;
    width: auto;
  }

  .form {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 16px;

    .controls {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 6px;
      width: 100%;
    }
  }

  .button {
    width: 100%;
  }
}
