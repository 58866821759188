@import '~static/styles/mixins';
@import '~static/styles/variables';

.AdminReviewCard {
  padding: 16px;
  display: flex;
  flex-direction: row;

  .data {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    width: 100%;
  }

  .info {
    .link {
      display: inline;
    }
  }

  .controls {
    margin: 0 0 auto 18px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    flex-shrink: 0;

    .button {
      color: $gray;

      &.edit:hover {
        color: $green;
      }

      &.delete:hover {
        color: $red;
      }

      .icon {
        width: 18px;
        height: auto;
      }
    }
  }

  .message {
    white-space: pre-wrap;
    margin: 0;
  }
}
