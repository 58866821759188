@import '~static/styles/mixins';
@import '~static/styles/variables';

.AdminTransactionUpdateModal {
  max-width: 420px;

  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}
