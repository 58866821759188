// Inter

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url('../fonts/Inter/Inter-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url('../fonts/Inter/Inter-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('../fonts/Inter/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../fonts/Inter/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('../fonts/Inter/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('../fonts/Inter/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('../fonts/Inter/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('../fonts/Inter/Inter-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url('../fonts/Inter/Inter-Black.woff2') format('woff2');
}

// Montserrat

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2');
}
