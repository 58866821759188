@import '~static/styles/mixins';
@import '~static/styles/variables';

.FeedbackModal {
  width: 300px;

  .message {
    text-align: center;
    margin: 0 0 40px;
  }

  .button {
    width: 100%;
  }
}
