@import '~static/styles/variables';

.Dropzone {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 16px;

  background: $white;
  box-shadow: inset 0 0 0 1px $white-gray;
  border-radius: 10px;

  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus-within {
    box-shadow: inset 0 0 0 1px $black-gray;
  }

  &.hasError {
    box-shadow: inset 0 0 0 1px $red;

    .text .hint {
      color: $red;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.4;
  }

  .overlay {
    pointer-events: none;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    backdrop-filter: grayscale(1);
  }

  .icon {
    color: $black-transparent;
  }

  .icon,
  .preview {
    width: 70px;
    height: 50px;
    flex-shrink: 0;
    margin: 0 10px 0 0;
  }

  .preview {
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }

  .text {
    display: flex;
    flex-direction: column;

    .name {
      margin: 0 0 5px;
      font-size: 18px;
    }

    .hint {
      font-size: 14px;
      word-break: break-all;
    }
  }
}
