@import '~static/styles/mixins';
@import '~static/styles/variables';

.BoomboxStatsFilterModal {
  max-width: 300px;
  width: 100%;

  .title {
    margin: 0 0 16px;
  }

  .clonesSwitcher {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    margin: 0 0 16px;

    .option {
      padding: 8px;

      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 0;
      border-bottom: 1px solid $dark-gray;

      &.selected {
        color: $green;
        border-color: $green;
      }
    }
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin: 0 0 40px;

    .filter {
      display: flex;
      flex-direction: column;

      .label {
        margin: 0 0 8px;
      }

      .options {
        display: grid;
        width: 100%;
        gap: 8px;

        &.line {
          grid-template-columns: repeat(5, 1fr);
        }

        &.places {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      .filterOption {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #b4b4b4;

        color: $night-green;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;

        &.active {
          color: $white;
          background: $dark-green;
          box-shadow: inset 0 0 0 1px $light-green;
        }
      }

      .deskGroup {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin: 0 0 8px;
        }

        .options {
          grid-template-columns: repeat(6, 1fr);
          width: 100%;
        }

        .filterOption.active {
          box-shadow: none;
        }

        &.bronze {
          .filterOption.active {
            background: linear-gradient(39.52deg, #702105 3.27%, #ffd0b6 100%);
            color: $white;
          }
        }

        &.silver {
          .filterOption.active {
            background: linear-gradient(0deg, #ffffff, #ffffff),
              linear-gradient(41.64deg, #576265 0%, #9ea1a1 55.02%, #929797 100%);
            background-blend-mode: color, normal;
            color: $white;
          }
        }

        &.gold {
          .filterOption.active {
            background: linear-gradient(34.07deg, #d99d3a 0%, #f4f1b5 75%, #d59f2a 100%);
            color: #260900;
          }
        }
      }
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
