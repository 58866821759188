@import '~static/styles/mixins';
@import '~static/styles/variables';

.MagicBoxLotsFilterModal {
  max-width: 360px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: 16px 16px 8px;
  border-radius: 8px;

  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    width: 100%;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin: 0;
    }

    .close {
      color: $black;

      .icon {
        width: 32px;
        height: auto;
      }
    }
  }

  .card {
    border-radius: 8px;
    border: 1px solid #ebebeb;
    padding: 16px;

    .label {
      margin: 0 0 8px;
    }

    .fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }

    .fieldWrapper {
      position: relative;

      .prefix {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }

      .input input {
        padding-left: 56px;
        font-weight: 500;
        color: $black;
      }
    }

    .checkboxes {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;

      .checkbox {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        & > span:last-child {
          margin: 0 10px 0 0;
        }
      }
    }
  }

  .button {
    width: 100%;

    &.close {
      box-shadow: none;
    }
  }
}
