@import '~static/styles/mixins';
@import '~static/styles/variables';

.JoinMagicBoxLotModal {
  max-width: 320px;
  width: 100%;

  .heading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    align-items: center;
    gap: 8px 30px;

    .image {
      position: absolute;
      top: 11px;
      right: 0;
      width: 90px;
      height: auto;
    }
  }

  .content,
  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    width: 100%;
  }

  .content {
    padding: 16px 16px 8px;
  }

  .title {
    margin: 0;
    text-align: center;
  }

  .user {
    display: flex;
    align-items: center;

    .avatar {
      margin: 0 10px 0 0;
      width: 40px;
      height: 40px;
    }

    .text {
      display: flex;
      flex-direction: column;
    }
  }

  .delimeter {
    width: 100%;
    height: 1px;
    background: rgba($black, 0.1);
  }

  .fieldWrapper,
  .input {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .fieldWrapper {
    .label {
      margin: 0 0 16px;
    }

    .hint {
      margin: 8px 0 0;
    }
  }

  .input {
    & > span:first-child {
      margin: 0 0 16px;

      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .button {
    width: 100%;

    &.close {
      box-shadow: none;
    }
  }
}
