@import './mixins';
@import-normalize;

html {
  box-sizing: border-box;
  background-color: $bone-white;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
  @include pretty-scrollbar;
}

body {
  margin: 0;
  font-family: $inter;
  color: $black-transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > a:last-child {
    display: none;
  }
}
