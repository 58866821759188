@import '~static/styles/mixins';
@import '~static/styles/variables';

.MagicBoxLotParticipantCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 176px;

  background: #e7e9f0;
  border-radius: 8px;
  padding: 16px;

  &.taken {
    padding: 8px;
    background: radial-gradient(59.5% 46.9% at 50% 53.1%, #09513c 0%, #0c272d 100%);
  }

  &.empty {
    .chair {
      opacity: 0.4;
    }
  }

  &.winner {
    padding: 16px;
    background: $green;

    .avatar {
      margin: 0 0 8px;
    }

    .username {
      margin: 0;
    }

    .counter {
      margin: -8px auto 8px -8px;

      .iconWrapper {
        box-shadow: inset 0 0 0 1px $white;
      }
    }
  }

  .chair {
    width: 64px;
    height: auto;
    margin: auto;
    color: $black;
  }

  .button {
    margin: 32px 0 0;
    width: 100%;
  }

  .counter {
    display: flex;
    align-items: center;
    margin: 0 auto 8px 0;

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
      border-radius: 6px;
      background: $green;

      .icon {
        width: 16px;
        height: auto;
        color: $white;
      }
    }
  }

  .avatar {
    width: 72px;
    height: 72px;
    margin: 0 0 16px;
  }

  .username {
    margin: auto 0 8px;
  }

  .delimeter {
    width: 100%;
    height: 1px;
    margin: 12px 0;
    background: rgba($white, 0.3);
  }

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
