@import '~static/styles/mixins';
@import '~static/styles/variables';

.TictacPromoModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: calc(100% - 64px);
  max-width: 320px;
  padding: 32px 24px;

  background: linear-gradient(115.22deg, #0c272d -38.37%, #053d49 -38.35%, #043f43 4.17%, #004a1e 135.61%);

  .title {
    margin: 0 0 16px;
    font-family: $montserrat;
    text-transform: uppercase;
    font-weight: 700;
  }

  .text {
    margin: 0 0 8px;
  }

  .subtitle {
    margin: 0 0 32px;
    text-transform: uppercase;
  }

  .link {
    margin: 0 auto 0 0;
  }

  .image {
    position: absolute;
    pointer-events: none;
    height: auto;
  }

  .box {
    width: 207px;
    bottom: -70px;
    right: -24px;
  }

  .tictacOrange {
    width: 56px;
    right: -16px;
    top: 90px;
  }

  .tictacGreen {
    width: 56px;
    top: -32px;
    right: 100px;
  }

  .tictacYellow {
    z-index: -1;
    width: 68px;
    left: -32px;
    top: 4px;
  }

  .close {
    position: absolute;
    top: -40px;
    right: 0;
    color: $white;

    .icon {
      width: 32px;
      height: auto;
    }
  }
}
