@import '~static/styles/mixins';
@import '~static/styles/variables';

.PasswordField {
  position: relative;

  .toggleButton {
    color: $black-gray;
    position: absolute;
    bottom: 13px;
    right: 16px;

    &:hover,
    &:active {
      color: $green;
    }

    .eyeIcon {
      width: 24px;
      height: auto;
    }
  }

  input {
    padding-right: 48px !important;
  }
}
