@import '~static/styles/mixins';
@import '~static/styles/variables';

.DatePicker {
  position: relative;
  display: inline-flex;
  width: 100%;

  .input input {
    caret-color: transparent;
  }

  .pickerCard {
    z-index: $dropdown-layer;
    position: absolute;
    top: calc(100% + 20px);
    left: 0;

    &.placementTop {
      top: unset;
      bottom: calc(100% + 20px);
    }

    &.placementLeft {
      left: unset;
      right: 0;
    }
  }

  :global {
    .rdrMonth {
      width: 300px;
    }

    .rdrDefinedRangesWrapper {
      display: none;
    }

    .rdrNextPrevButton {
      background-color: $white;

      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: rgba($green, 0.8);
      }

      &:active {
        background-color: rgba($green, 0.9);
      }

      &.rdrPprevButton {
        i {
          border-right-color: $gray;
        }

        &:hover,
        &:active {
          i {
            border-right-color: $white;
          }
        }
      }

      &.rdrNextButton {
        i {
          border-left-color: $gray;
        }

        &:hover,
        &:active {
          i {
            border-left-color: $white;
          }
        }
      }
    }

    .rdrMonthAndYearPickers select {
      color: $black;
      text-transform: capitalize;
    }

    .rdrWeekDay {
      text-transform: capitalize;
    }

    .rdrDayPassive .rdrDayNumber span {
      color: $gray;
    }

    .rdrCalendarWrapper {
      color: $black;
      background: transparent;
    }

    .rdrDayNumber {
      font-weight: 400;

      span {
        color: $black;
      }
    }

    .rdrDayDisabled {
      background: $white;

      .rdrDayNumber span {
        color: $light-gray;
      }
    }

    .rdrDayToday .rdrDayNumber span::after {
      background: $green;
    }
  }
}
