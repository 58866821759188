@import '~static/styles/mixins';
@import '~static/styles/variables';

.CreateNotificationModal {
  max-width: 300px;
  width: 100%;

  .title {
    margin: 0 0 16px;
  }

  .form {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 16px;

    .controls {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 6px;
      width: 100%;
    }
  }

  .button {
    width: 100%;
  }
}
