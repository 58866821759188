@import '~static/styles/mixins';
@import '~static/styles/variables';

.MainMarketingStatsFilterModal {
  max-width: 300px;
  width: 100%;

  .title {
    margin: 0 0 16px;
  }

  .clonesSwitcher {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    margin: 0 0 16px;

    .option {
      padding: 8px;

      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 0;
      border-bottom: 1px solid $dark-gray;

      &.selected {
        color: $green;
        border-color: $green;
      }
    }
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin: 0 0 40px;

    .filter {
      display: flex;
      flex-direction: column;

      .label {
        margin: 0 0 8px;
      }

      .options {
        display: grid;
        width: min-content;
        grid-template-columns: repeat(6, auto);
        gap: 8px;
      }

      .filterOption {
        width: 33px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #b4b4b4;

        color: $night-green;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;

        &.active {
          color: $white;
          background: $dark-green;
          border: 1px solid $light-green;
        }
      }

      .deskGroup {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin: 0 0 8px;
        }

        .options {
          grid-template-columns: repeat(4, 1fr);
          width: 100%;

          .filterOption {
            width: 100%;
          }
        }

        &.blue {
          .filterOption.active {
            background: $blue;
            border: 1px solid $blue;
          }
        }

        &.red {
          .filterOption.active {
            background: $crimson-red;
            border: 1px solid $crimson-red;
          }
        }
      }
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
