@import '~static/styles/variables';
@import '~static/styles/mixins';

.Select {
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  &.hasError {
    :global {
      .Select__control {
        box-shadow: inset 0 0 0 1px $red !important;
      }

      .Select__dropdown-indicator,
      .Select__dropdown-indicator:hover {
        color: $red;
      }
    }

    .iconStart,
    .iconEnd {
      color: $red;
    }

    .label {
      color: $red;
    }
  }

  &.disabled {
    opacity: 0.4;
  }

  .label {
    text-align: left;
    margin: 0 0 8px;
    transition: all 0.2s ease-in-out;
    color: $dark-gray;
  }

  .chevron,
  .clear {
    width: 22px;
    height: auto;
    color: $black-gray;
  }

  .iconStart {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: $black-gray;
  }

  .iconStart {
    margin: 0 15px 0 0;
  }

  .iconEnd {
    margin: 0 0 0 15px;
  }

  :global {
    .Select {
      &__control {
        position: relative;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        padding: 13px;
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $white-gray;
        border-radius: 10px;
        transition: all 0.2s ease-in-out;

        &:hover,
        &--is-focused,
        &--is-focused:hover {
          box-shadow: inset 0 0 0 1px $black-gray;
          border: none;
        }

        &--menu-is-open {
          .Select__dropdown-indicator {
            transform: rotate(180deg);
          }
        }
      }

      &__placeholder {
        color: $gray;
        font-size: 16px;
        line-height: 24px;
      }

      &__value-container {
        padding: 0;
        margin: auto auto auto 0;
        flex: unset;
        flex-shrink: 0;
        overflow: visible;
        /* flex-direction: row-reverse; */

        & > :last-child {
          margin: 0;
          padding: 0;
        }
      }

      &__value-containter,
      &__input-container,
      &__single-value {
        color: $dark-gray;
        font-family: inherit;
        font-size: 16px;
        line-height: 24px;
        background: none;
        padding: 0;
      }

      &__input {
        margin: auto 0 0;
        font-size: 16px;
        line-height: 24px;
      }

      &__single-value {
        display: flex;
        flex: 1;
        position: static;
        transform: none;
        margin: auto 0 0;
        max-width: unset;
      }

      &__multi-value {
        background: $green;

        &__label {
          padding: 2px 4px;
          color: $white;
        }

        &__remove {
          cursor: pointer;
          color: $white;
          transition: all 0.2s ease-in-out;

          &:hover {
            color: $white;
            background: $gray;
          }
        }
      }

      &__indicators {
        align-self: center;
        margin: auto 0 auto 10px;
      }

      &__indicator-separator {
        display: none;
      }

      &__clear-indicator {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 0.9;
        }
      }

      &__dropdown-indicator,
      &__clear-indicator {
        padding: 0;
        transition: all 0.2s ease-in-out;
        color: $black-gray;

        &:hover {
          color: $black-gray;
        }
      }

      &__menu {
        border-radius: 0;
        border: none;
        border-radius: 10px;
        padding: 0 10px 0 0;
        z-index: 2;

        &:local {
          animation: menuFadeUp 0.3s ease-in-out;
        }

        @keyframes menuFadeUp {
          0% {
            opacity: 0;
            transform: translateY(40px);
          }

          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        &-list {
          display: flex;
          flex-direction: column;
          padding: 15px 20px;

          @include pretty-scrollbar;
        }
      }

      &__option {
        display: flex;
        align-items: center;
        width: auto;
        padding: 5px 0;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        color: $dark-gray;
        font-size: 16px;
        line-height: 24px;

        &--is-focused {
          background: none;
          color: $black;
        }

        &--is-selected,
        &:active {
          color: $green;
          background: none;
        }

        :local .optionIcon {
          width: 22px;
          height: 22px;
          margin: 0 10px 0 0;
          flex-shrink: 0;
        }
      }
    }
  }
}
