@import '~static/styles/mixins';
@import '~static/styles/variables';

.SubPage {
  display: flex;
  flex-direction: column;

  .backLink {
    margin: 0 auto 16px 0;

    &.onMobile {
      @include respond-above(lg) {
        display: none;
      }
    }
  }
}
