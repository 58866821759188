@import '~static/styles/mixins';
@import '~static/styles/variables';

.KlondikeLockedDeskModal {
  max-width: 510px;

  .title {
    margin: 0 0 16px;
  }

  .text {
    margin: 0 0 32px;
  }

  .button {
    width: 100%;
  }
}
