@import '~static/styles/mixins';
@import '~static/styles/variables';

.Avatar {
  position: relative;

  &.circle {
    .avatar {
      border-radius: 50%;
    }
  }

  &.square {
    .avatar {
      border-radius: 10px;
    }
  }

  .avatar {
    overflow: hidden;
    width: 100% !important;
    height: 100% !important;
  }

  &.xxl {
    width: 256px;
    height: 256px;
  }
  &.xl {
    width: 128px;
    height: 128px;
  }
  &.lg {
    width: 80px;
    height: 80px;
  }
  &.md {
    width: 64px;
    height: 64px;
  }
  &.sm {
    width: 32px;
    height: 32px;
  }

  .hat {
    position: absolute;
    transform: rotate(-30deg);
    left: -12px;
    top: -20px;

    width: 32px;
    height: auto;

    @include respond-above(lg) {
      left: -16px;
      top: -32px;
      width: 48px;
    }
  }
}
