@import '~static/styles/mixins';
@import '~static/styles/variables';

.InstallTopboxPlaceModal {
  max-width: 260px;

  .hint {
    margin: 0 0 16px;
  }

  .title {
    margin: 0 0 24px;
  }

  .switcher {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
    margin: 0 0 40px;

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      min-height: 160px;
      border-radius: 8px;
      background: $white;

      .icon {
        color: $green;
        width: 32px;
        height: auto;
        transition: all 0.3s ease-in-out;
      }

      .label {
        margin: 16px 0 0;
        transition: all 0.3s ease-in-out;
      }

      .check {
        width: auto;
        height: 0px;
        color: $bright-gray;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        opacity: 1;

        .label {
          color: $green;
        }
      }

      &.active {
        background: $green;
        box-shadow: 0px 16px 24px rgba($black, 0.4);
        border-radius: 16px;

        .icon,
        .label {
          color: $white;
        }

        .label {
          margin: 8px 0 8px;
        }

        .check {
          color: $white;
          height: 32px;
        }
      }
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    .button {
      width: 100%;
    }
  }

  .inputLabel {
    margin: 0 0 16px;
  }

  .input {
    margin: 0 0 8px;

    input {
      padding: 10px 16px;
      background: $white;
      border-radius: 8px;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .partnersList {
    width: 100%;
    height: 270px;
    padding: 14px 0;
    margin: 0 0 24px;
    overflow: auto;

    @include pretty-scrollbar;

    .noPartners {
      margin: auto;
    }

    .partner {
      display: flex;
      align-items: center;
      padding: 3px 16px;
      justify-content: flex-start;
      border-radius: 0;

      &:hover {
        opacity: 1;
        background: $bone-white;
      }

      &.selected {
        .check {
          opacity: 1;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .count {
          color: $gray;
          background: $bone-white;
        }
      }

      .check {
        color: $green;
        width: 16px;
        height: auto;
        margin: 0 0 0 auto;
        opacity: 0;

        transition: all 0.3s ease-in-out;
      }
    }
  }
}
