@import '~static/styles/mixins';
@import '~static/styles/variables';

.TopboxReferralInfoModal {
  padding: 24px;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid rgba($white, 0.4);
  border-radius: 16px;
  color: $white;

  &.emerald {
    background: linear-gradient(154deg, #003c00 -212.76%, #0c272d 100%);

    .info {
      background: #071e1a;

      li {
        border-color: #163137;
      }
    }
  }

  &.ruby {
    background: linear-gradient(154deg, #7c0107 -212.76%, #0c272d 100%);

    .info {
      background: #18161b;

      li {
        border-color: #222025;
      }
    }
  }

  &.diamond {
    background: linear-gradient(154deg, #1f28ba -212.76%, #0c272d 100%);

    .info {
      background: #0b1c33;

      li {
        border-color: #1f3047;
      }
    }
  }

  .heading {
    display: flex;
    align-items: center;
    margin: 0 0 16px;

    .icon {
      height: 32px;
      width: auto;
      margin: 0 16px 0 0;
    }
  }

  .title {
    text-transform: uppercase;
    margin: 0 0 16px;
    font-weight: 800;
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    margin: 0 0 16px;
    list-style: none;

    .label {
      opacity: 0.8;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        padding: 0 0 8px;
        margin: 0 0 12px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
      }
    }
  }

  .close {
    width: 100%;
  }
}
