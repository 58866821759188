@import '~static/styles/mixins';
@import '~static/styles/variables';

.KlondikeStatsFilterModal {
  max-width: 300px;
  width: 100%;

  .title {
    margin: 0 0 16px;
  }

  .clonesSwitcher {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    margin: 0 0 16px;

    .option {
      padding: 8px;

      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 0;
      border-bottom: 1px solid $dark-gray;

      &.selected {
        color: $green;
        border-color: $green;
      }
    }
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin: 0 0 40px;

    .filter {
      display: flex;
      flex-direction: column;

      .label {
        margin: 0 0 8px;
      }

      .options {
        display: grid;
        width: 100%;
        gap: 8px;

        &.line {
          grid-template-columns: repeat(5, 1fr);
        }

        &.places {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .filterOption {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #b4b4b4;

        color: $night-green;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;

        &.active {
          color: $white;
          background: $dark-green;
          box-shadow: inset 0 0 0 1px $light-green;
        }
      }

      .deskGroup {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin: 0 0 8px;
        }

        .options {
          grid-template-columns: repeat(6, 1fr);
          width: 100%;
        }

        .filterOption.active {
          box-shadow: none;
        }

        &.red {
          .filterOption.active {
            background: linear-gradient(0deg, #c95c5c 0%, #c95c5c 100%),
              linear-gradient(339deg, rgba(255, 255, 255, 0) 70%, white 100%),
              linear-gradient(
                77deg,
                #576265 0%,
                #9ea1a1 17%,
                #848b8a 46%,
                #576265 55%,
                #576265 82%,
                #757a7b 93%,
                #576265 100%
              );
            background-blend-mode: color, overlay, normal;
          }
        }

        &.yellow {
          .filterOption.active {
            background: linear-gradient(0deg, #d09742 0%, #d09742 100%),
              linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
              linear-gradient(
                76.82deg,
                #576265 11.6%,
                #9ea1a1 25.31%,
                #848b8a 48.06%,
                #576265 55.72%,
                #576265 77.23%,
                #757a7b 85.34%,
                #576265 91.31%
              );
            background-blend-mode: color, overlay, normal;
          }
        }

        &.white {
          .filterOption.active {
            background: linear-gradient(0deg, #cdc9c2 0%, #cdc9c2 100%),
              linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
              linear-gradient(
                76.82deg,
                #576265 11.6%,
                #9ea1a1 25.31%,
                #848b8a 48.06%,
                #576265 55.72%,
                #576265 77.23%,
                #757a7b 85.34%,
                #576265 91.31%
              );
            background-blend-mode: color, overlay, normal;
          }
        }
      }
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
