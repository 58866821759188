@import '~static/styles/mixins';
@import '~static/styles/variables';

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $night-green;
  z-index: $menu-content-layer;

  @include respond-above(lg) {
    display: none;
  }

  .menu {
    color: $bone-white;
  }

  .logo {
    .icon {
      height: 28px;
    }

    .text {
      font-size: 16px;
      line-height: 100%;
    }
  }
}
