@import '~static/styles/mixins';
@import '~static/styles/variables';

.PurchaseTopboxDeskModal {
  max-width: 260px;

  .title {
    margin: 0 0 16px;
  }

  .text {
    margin: 0 0 24px;
  }

  .hint {
    margin: 0 0 8px;
  }

  .price {
    margin: 0 0 40px;

    span {
      color: $green;
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    .button {
      width: 100%;
    }
  }
}
