@import '~static/styles/variables';

.TextField {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  transition: all 0.2s ease-in-out;
  cursor: text;

  &.hasError {
    .input {
      box-shadow: inset 0 0 0 1px $red;
    }

    .label,
    .iconStart,
    .iconEnd {
      color: $red;
    }
  }

  &.hasIconStart {
    .input {
      padding: 13px 13px 13px 50px;
    }
  }

  &.hasIconEnd {
    .input {
      padding: 13px 50px 13px 13px;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.hasMaxLengthLabel .input {
    padding-bottom: 28px;
  }

  .label {
    text-align: left;
    margin: 0 0 8px;
    transition: all 0.2s ease-in-out;
  }

  .iconStart,
  .iconEnd {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: $black-gray;
  }

  .iconStart {
    left: 13px;
  }

  .iconEnd {
    right: 13px;
  }

  .counter {
    position: absolute;
    bottom: 8px;
    right: 20px;
  }

  .input {
    width: 100%;
    color: $gray;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    background: none;
    border: none;
    outline: none;
    padding: 13px;
    resize: none;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    color: $gray;
    box-shadow: inset 0 0 0 1px $white-gray;
    appearance: none;

    &::placeholder {
      transition: all 0.2s ease-in-out;
      font-family: inherit;
      opacity: 1;
      color: $gray;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }

    &:focus,
    &:hover {
      box-shadow: inset 0 0 0 1px $black-gray;
    }
  }
}
