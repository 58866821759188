// Fonts
$inter: 'Inter', sans-serif;
$montserrat: 'Montserrat', sans-serif;

// Colors
$white: #ffffff;
$bone-white: #f4f5fc;
$red: #f13838;
$crimson-red: #ee1212;
$blue: #0066ff;
$green: #00b548;
$green-transparent: rgba($green, 0.2);
$dark-green: #009b3e;
$light-green: #24c26c;
$night-green: #0c272d;
$black-green: #001319;
$black: #0d0d19;
$black-pearl: #00090f;
$black-transparent: rgba($black, 0.7);
$light-gray: #b4b4b4;
$white-gray: #d9dee2;
$bright-gray: #d9d9d9;
$gray: #7b858f;
$dark-gray: #434d56;
$black-gray: #212b36;
$shaft-gray: #2b2b2b;
$jet-black: #343434;
$jaguar-gray: #232c34;

$emerald-dark: #0c272d;
$emerald-light: #163137;
$emerald-black: #021d23;
$ruby-dark: #250d15;
$ruby-black: #120000;
$ruby-pale: #420c16;
$diamond-dark: #001d38;
$diamond-light: #1a94ff3d;
$diamond-pale: #0a2742;

$bronze: #f5ddcf;
$darkBronze: #643b35;
$silver: #ebebeb;
$darkSilver: #404040;
$gold: #ffffcb;

// Gradients
$green-gradient: linear-gradient(12.14deg, $night-green 4.57%, $green 205.44%);
$red-gradient: linear-gradient(28.01deg, #000000 0%, #ab1d1d 100%);
$blue-gradinet: linear-gradient(28.01deg, #00325f 0%, #005baf 100%);
$white-text-gradient: linear-gradient(180deg, #ffffff 0.01%, #eeeeee 100%);

$bronze-header-gradient: linear-gradient(
  83.71deg,
  #702105 16.73%,
  #daab91 40.22%,
  #a66d5c 63.72%,
  #633325 80.78%,
  #8c4f3d 104.74%,
  #e1b28e 126%,
  #643b35 157.7%
);
$bronze-card-gradient: linear-gradient(34.07deg, #6c392b 0%, #7e4635 50%, #c99677 100%);
$bronze-button-gradient: linear-gradient(
  82.72deg,
  #702105 -106.92%,
  #daab91 -49.62%,
  #a66d5c 7.68%,
  #633325 49.29%,
  #8c4f3d 107.72%,
  #e1b28e 159.56%,
  #643b35 236.86%
);
$bronze-border-gradient: linear-gradient(
  82.61deg,
  #702105 -9.28%,
  #daab91 52.15%,
  #a66d5c 104.8%,
  #633325 182.02%,
  #8c4f3d 217.13%,
  #e1b28e 282.06%,
  #643b35 327.7%
);

$silver-header-gradient: linear-gradient(0deg, #cdc9c2, #cdc9c2),
  linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
  linear-gradient(
    259.14deg,
    #576265 -32.2%,
    #9ea1a1 -13.54%,
    #848b8a 17.4%,
    #576265 27.83%,
    #576265 57.09%,
    #757a7b 68.12%,
    #576265 76.24%
  );
$silver-card-gradient: linear-gradient(34.07deg, #404040 0%, #868686 50%, #888888 100%);
$silver-button-gradient: linear-gradient(0deg, #cdc9c2, #cdc9c2),
  linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
  linear-gradient(
    257.16deg,
    #576265 -161.07%,
    #9ea1a1 -110.82%,
    #848b8a -27.46%,
    #576265 0.63%,
    #576265 79.45%,
    #757a7b 109.17%,
    #576265 131.03%
  );
$silver-border-gradient: linear-gradient(
  39.57deg,
  #576265 56.2%,
  #9ea1a1 81.88%,
  #848b8a 124.48%,
  #576265 138.84%,
  #576265 179.12%,
  #757a7b 194.31%,
  #576265 205.48%
);

$gold-header-gradient: linear-gradient(87.55deg, #dba73e 0%, #f7ed9a 33.33%, #d0ab5b 66.67%, #e0c77d 100%);
$gold-card-gradient: linear-gradient(34.07deg, #d99d3a 0%, #f4f1b5 75%, #d59f2a 100%);
$gold-button-gradient: linear-gradient(87.55deg, #dba73e 0%, #f7ed9a 33.33%, #d0ab5b 66.67%, #e0c77d 100%);
$gold-border-gradient: linear-gradient(
    264.56deg,
    #576265 -17.8%,
    #9ea1a1 3.65%,
    #848b8a 39.23%,
    #576265 51.22%,
    #576265 84.87%,
    #757a7b 97.55%,
    #576265 106.89%
  ),
  linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%), rgba(255, 205, 104, 1);

$tictac-yellow: #ffe925;
$tictac-green: #63dd03;
$tictac-orange: #ffc700;
$tictac-yellow-border: #846b12;
$tictac-green-border: #27680e;
$tictac-orange-border: #ac5d00;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

// Layers
$modal-overlay-layer: 100;
$modal-content-layer: 101;
$menu-overlay-layer: 10;
$menu-content-layer: 11;
$dropdown-layer: 1000;
