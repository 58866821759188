@import '~static/styles/mixins';
@import '~static/styles/variables';

.Breadcrumbs {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-template-rows: max-content;
  flex-shrink: 0;
  gap: 8px;
  width: 100%;
  overflow: auto;

  @include pretty-scrollbar;

  @include respond-above(lg) {
    gap: 20px;
  }

  .delimeter {
    width: 24px;
    height: 1px;
    background: $light-gray;
    border-radius: 1px;
  }

  .link {
    width: fit-content;
    white-space: nowrap;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    @include respond-above(lg) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
